<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <div class="text-h4 ml-1 mt-2">{{ user.username }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-list class="information-wrapper">
          <v-list-item v-if="isAdmin">
            <v-list-item-avatar>
              <v-icon color="green">mdi-shield-crown</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Moderador
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(item, index) in userInfo" :key="index">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title v-if="!item.editable">{{ item.value }}</v-list-item-title>
              <v-text-field v-else v-model="item.value"></v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="item.label != 'createdAt'" @click="toggleEditMode(index)">
                {{ item.editable ? 'mdi-check' : 'mdi-pencil' }}
              </v-icon>
              <v-icon v-if="item.editable" color="green" @click="saveUserInfo(index)">mdi-content-save</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="8">

        <v-data-table :headers="headers" :items="denuncias" :items-per-page="10" show-expand :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" :expanded.sync="expanded" locale="pt" class="elevation-1">
          <template v-slot:[`item.publicado`]="{ item }">
            <div v-if="item.publicado">
              <v-chip color="green" style="color: white;">Publicado</v-chip>
            </div>
            <div v-else>
              <v-chip color="error">
                Em análise
              </v-chip>
            </div>
          </template>
          <template v-slot:[[`item.publishedAt`]]="{ item }">
            <span>{{ new Date(item.publishedAt).toLocaleString() }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row class="my-2">
                <v-col>

                  <div><b>Categoria: </b> {{ item.categories }}</div>
                  <div><b>Elemento: </b> {{ item.elements }}</div>
                  <div><b>Impactos: </b> {{ item.impacts }}</div>
                  <div v-if="item.photo"><b>fotos: </b> {{ item.photo.length }}</div>
                  <div v-if="item.relato"><b>Relato: </b> {{ item.relato }}</div>
                </v-col>
                <v-col cols="text-center align-center">
                  <div class="text-center" style="width: 100%" v-if="item.photo">
                    <v-img v-if="item.photo.length == 1" height="300px" width="300" :src="`${item.photo[0].url}`">
                    </v-img>


                    <v-carousel v-else class="carousel-img" cycle height="300" width="300" hide-delimiters
                      hide-delimiter-background>
                      <v-carousel-item v-for="(slide, i) in item.photo" :key="i" :src="getSrcImg(slide)" />
                    </v-carousel>
                  </div>
                </v-col>

              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import request from "@/router/requests.js";

export default {
  data() {
    return {
      user: { role: { type: '' } },
      userInfo: [
        { label: 'email', value: '', editable: false, icon: 'mdi-email', tooltip: "E-mail" },
        { label: 'telefone', value: '', editable: false, icon: 'mdi-phone', tooltip: "Telefone" },
        { label: 'cidade', value: '', editable: false, icon: 'mdi-home-city', tooltip: "Cidade" },
        { label: 'associacao', value: '', editable: false, icon: 'mdi-account-group', tooltip: "Associação" },
        { label: 'createdAt', value: '', editable: false, icon: 'mdi-clock', tooltip: "Criado em" },
      ],
      denuncias: [],
      apiUrl: process.env.VUE_APP_STRAPI_URL,
      expanded: [],
      sortBy: "publishedAt",
      sortDesc: true,
      headers: [
        {
          text: "Codigo da denúncia",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Publicada",
          align: "start",
          sortable: true,
          value: "publicado",
        },
        {
          text: "Data da criação",
          align: "start",
          sortable: true,
          value: "publishedAt",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ],
    }
  },
  async created() {
    await request("GET", `users/me?populate=*`).then((response) => {
      this.user = response;
      this.userInfo.forEach(element => {
        if (this.user[element.label]) {
          if (element.label == 'createdAt') {
            element.value = new Date(this.user[element.label]).toLocaleDateString(
              "pt-BR",
              { year: "numeric", month: "2-digit", day: "2-digit" }
            )
          } else {
            element.value = this.user[element.label]
          }
        } else {
          element.value = '--'
        }
      });
    })

    await request("GET", `denuncias?filters'\'[users_permissions_user.id][$eq]=${this.user.id}&populate=*`).then((resp) => {
      const data = resp.data;
      const filtered = data.filter((den) => den.users_permissions_user.id == this.user.id)
      this.denuncias = filtered;

    })
  },
  methods: {
    async saveUserInfo(index) {
      if (this.userInfo[index].label == 'createdAt') {
        return
      }
      let att = this.userInfo[index].label
      let obj = {
        "username": this.user.username,
      }

      obj[att] = this.userInfo[index].value

      await request("PUT", `users/${this.user.id}`, obj).then(
        this.userInfo[index].editable = false
      )
    },
    toggleEditMode(index) {
      this.userInfo[index].editable = !this.userInfo[index].editable;
    },
    getSrcImg(photo) {
      if (photo.formats.length > 1) {
        return photo.formats.small.url
      } else {
        return photo.url
      }
    },
  },
  computed: {
    isAdmin() {
      let user = JSON.parse(localStorage.getItem("userData"));
      if (user.role.type == 'admin_350') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.information-wrapper {
  background-color: rgb(242, 242, 242) !important;
  border-radius: 16px;
}
</style>